<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Edit Lost & Found') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm
          ref="form"
          :value="lostFound"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
          @image-removed="handleImageRemoved"
        />
        <a-alert v-if="submitted" type="success">
          <template #message>
            <span v-html="$t('updated', { name: lostFound.name })" />
            <router-link to="/lost-founds">
              {{ $t('view') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Edit Lost & Found": "Edit Lost & Found",
"updated": "Lost & Found <strong>{name}</strong> updated,",
"view": "View Lost & Found"
},
"ja": {
"Edit Lost & Found": "忘れ物を編集",
"updated": "忘れ物 <strong>{name}</strong> が更新されました。",
"view": "忘れ物を閲覧"
}
}
</i18n>

<script>
import EditForm from '@/views/lost-found/components/LostFoundEditForm';
import { appendImageUrl, getResponseError } from '@/utils/util';

export default {
  name: 'Add',
  components: { EditForm },
  data() {
    return {
      lostFound: undefined,
      isSubmitting: false,
      error: '',
      submitted: false,
      removedImage: [],
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'lost-found-edit') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('lost-founds/get', this.$route.params.id)
        .then((resp) => {
          this.lostFound = {
            name: resp.name,
            id: resp.id,
            description: resp.description,
            roomId: resp.roomId,
            propertyId: resp.propertyId,
            checkoutDate: resp.checkoutDate,
            status: resp.status,
            images: appendImageUrl(resp.images),
          };
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    async handleSubmit(data) {
      try {
        const { id } = this.lostFound;
        this.isSubmitting = true;
        this.submitted = false;
        this.lostFound = undefined;
        this.error = '';
        const { images, ...rest } = data;
        const resp = await this.$store.dispatch('lost-founds/update', { id, ...rest });
        if (images.length) {
          const imgPayload = {
            id,
            file: images,
          };
          await this.$store.dispatch('lost-founds/addImages', imgPayload);
        }
        if (this.removedImage.length) {
          await Promise.all(
            this.removedImage.map(async (img) => {
              if (img.id) {
                await this.$store.dispatch('lost-founds/deleteImage', { id, imageId: img.id });
              }
            }),
          );
        }
        this.$message.success('Update success');
        this.isSubmitting = false;
        this.lostFound = {
          name: resp.name,
          id: resp.id,
          description: resp.description,
          roomId: resp.roomId,
          propertyId: resp.propertyId,
          checkoutDate: resp.checkoutDate,
          status: resp.status,
          images: resp.images,
        };
        this.$refs.form.resetFields();
        this.submitted = true;
        this.$router.push({ name: 'lost-founds' });
      } catch (e) {
        this.isSubmitting = false;
        this.error = getResponseError(e);
      }
    },
    handleImageRemoved(file) {
      this.removedImage.push(file);
    },
  },
};
</script>
