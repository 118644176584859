<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add Lost & Found') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <LostFoundEditForm
          ref="form"
          :value="form"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
        />
        <a-alert v-if="lostFound" type="success">
          <template #message>
            <span v-html="$t('added', { name: lostFound.name })" />
            <a @click="handleViewClicked">
              {{ $t('view') }}
            </a>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add Lost & Found": "Add Lost & Found",
"added": "lost found <strong>{name}</strong> added,",
"view": "View Lost & Found"
},
"ja": {
"Add Lost & Found": "忘れ物を追加",
"added": "忘れ物 <strong>{name}</strong> が追加されました。",
"view": "忘れ物を閲覧"
}
}
</i18n>

<script>
import LostFoundEditForm from '@/views/lost-found/components/LostFoundEditForm';
import { getResponseError } from '@/utils/util';

export default {
  name: 'LostFoundAdd',
  components: { LostFoundEditForm },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'housekeeper-task-lost-found') {
        vm.fromHousekeeperTask = true;
      }
    });
  },
  data() {
    return {
      lostFound: undefined,
      fromHousekeeperTask: false,
      isSubmitting: false,
      error: '',
      form: {
        propertyId: undefined,
        roomId: undefined,
        checkoutDate: undefined,
      },
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'lost-found-add') {
        this.initData();
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.$route.query.propertyId) {
        this.form.propertyId = this.$route.query.propertyId;
      }
      if (this.$route.query.roomId) {
        this.form.roomId = parseInt(this.$route.query.roomId, 10);
      }
      if (this.$route.query.checkoutDate) {
        this.form.checkoutDate = this.$route.query.checkoutDate;
      }
    },
    async handleSubmit(form) {
      try {
        this.error = '';
        this.isSubmitting = true;
        this.lostFound = undefined;

        const { images, ...payload } = form;
        const { data } = await this.$store.dispatch('lost-founds/add', payload);

        if (images.length) {
          const imgPayload = {
            id: data.id,
            file: images,
          };

          await this.$store.dispatch('lost-founds/addImages', imgPayload);
        }

        this.isSubmitting = false;
        this.lostFound = data;
        this.$refs.form.resetFields();
      } catch (e) {
        this.isSubmitting = false;
        this.error = getResponseError(e);
      }
    },
    handleViewClicked() {
      if (this.fromHousekeeperTask) {
        return this.$router.go(-1);
      }

      return this.$router.push({ name: 'lost-founds' });
    },
  },
};
</script>
