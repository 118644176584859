{
  "en": {
    "Lost & Found": "Lost & Found",
    "Add Lost & Found": "Add Lost & Found",
    "Lost & Found Name": "Lost & Found Name",
    "Property": "Property",
    "Room Name": "Room Name",
    "Checkout date": "Checkout date",
    "Status": "Status"
  },
  "ja": {
    "Lost & Found": "忘れ物",
    "Add Lost & Found": "忘れ物を追加",
    "Lost & Found Name": "品名",
    "Property": "物件",
    "Room Name": "部屋",
    "Checkout date": "チェックアウト",
    "Status": "状況"
  }
}
